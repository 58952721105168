<script setup lang="ts">
import { UserFeedback } from '../../../submodules/feedback/types/UserFeedback'
import { Feedback, FeedbackModalOptions, FeedbackModalPhase, FeedbackModalStatus } from '../../types/Feedback'
import FeedbackService from '../../services/feedbackService'

const props = defineProps<{
	modalOptions: FeedbackModalOptions
	modalStatus: FeedbackModalStatus
	onUpdateModalPhase: (phase: FeedbackModalPhase) => void
	onUpdateModalStatus: (status: FeedbackModalStatus) => void
	onModalClose: (feedbackToGive?: UserFeedback) => void
}>()

const feedbackMap = {
	[Feedback.SUPER_SAD]: 1,
	[Feedback.SAD]: 3,
	[Feedback.NEUTRAL]: 5,
	[Feedback.HAPPY]: 7,
	[Feedback.SUPER_HAPPY]: 10,
}

const i18n = useI18n()
const { isMobile } = useMobileViewport()

const onFeedbackClick = (feedback: Feedback) => {
	const newFeedbackStatus: FeedbackModalStatus = {
		...props.modalStatus,
		chosenFeedback: feedback,
	}

	props.onUpdateModalStatus(newFeedbackStatus)
	props.onUpdateModalPhase(FeedbackModalPhase.Improvements)

	FeedbackService.trackScore(useFeedbackStore().activeFeedback!.eventKey, feedbackMap[feedback])
}

const titleFormatted = computed(() => {
	const modalHeader = i18n.t(props.modalOptions.modalHeader)

	// Extract highlighted text from modal header, identified in the format ==highlighted text==.
	const titleBeforeHighlight = modalHeader.substring(0, modalHeader.indexOf('=='))
	const titleAfterHighlight = modalHeader.substring(modalHeader.lastIndexOf('==') + 2)
	const titleHighlighted = modalHeader.substring(modalHeader.indexOf('==') + 2, modalHeader.lastIndexOf('=='))

	return [titleBeforeHighlight, titleHighlighted, titleAfterHighlight]
})
</script>

<template>
	<div class="flex w-full flex-col gap-3">
		<div class="flex w-full items-center justify-between">
			<SpText class="text-warm-grey-800" :size="SpTextSize.BodyMd03" :text="i18n.t(props.modalOptions.modalTitle)" />
			<SpButton
				:icon-name="SpCommonIcon.Times"
				:size="isMobile ? SpButtonSize.DefaultIconOnly : SpButtonSize.SmallIconOnly"
				:type="SpButtonType.Ghost"
				@click="onModalClose()"
			/>
		</div>
		<div class="h-[1px] w-full bg-warm-grey-200" />
		<div class="flex flex-col gap-3">
			<div class="mt-9 flex flex-col gap-9">
				<SpText class="text-center text-warm-grey-900" :size="SpTextSize.HeadingLg01">
					<span
						>👋 {{ titleFormatted[0] }}<span class="text-sky-600">{{ titleFormatted[1] }}</span
						>{{ titleFormatted[2] }}</span
					>
				</SpText>
				<SpText
					class="text-center text-warm-grey-700"
					:size="SpTextSize.BodyMd02"
					:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.CONTENT'])"
				/>
			</div>
			<div class="my-[60px] flex w-[80%] flex-row justify-between self-center">
				<SpTooltip
					class="feedback-icon h-[44px] w-[44px] justify-center rounded-full bg-warm-grey-100"
					:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_SUPER_SAD_EMOJI_TOOLTIP'])"
					:hide-cursor="true"
					@click="onFeedbackClick(Feedback.SUPER_SAD)"
				>
					<div class="flex h-[44px] w-[44px]">
						<span class="m-auto text-[22px]">😖</span>
					</div>
				</SpTooltip>
				<SpTooltip
					class="feedback-icon h-[44px] w-[44px] justify-center rounded-full bg-warm-grey-100"
					:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_SAD_EMOJI_TOOLTIP'])"
					:hide-cursor="true"
					@click="onFeedbackClick(Feedback.SAD)"
				>
					<div class="flex h-[44px] w-[44px]">
						<span class="m-auto text-[22px]">😔</span>
					</div>
				</SpTooltip>
				<SpTooltip
					class="feedback-icon flex h-[44px] w-[44px] justify-center rounded-full bg-warm-grey-100"
					:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_NEUTRAL_EMOJI_TOOLTIP'])"
					:hide-cursor="true"
					@click="onFeedbackClick(Feedback.NEUTRAL)"
				>
					<div class="flex h-[44px] w-[44px]">
						<span class="m-auto text-[22px]">😑</span>
					</div>
				</SpTooltip>
				<SpTooltip
					class="feedback-icon h-[44px] w-[44px] justify-center rounded-full bg-warm-grey-100"
					:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_HAPPY_EMOJI_TOOLTIP'])"
					:hide-cursor="true"
					@click="onFeedbackClick(Feedback.HAPPY)"
				>
					<div class="flex h-[44px] w-[44px]">
						<span class="m-auto text-[22px]">🙂</span>
					</div>
				</SpTooltip>
				<SpTooltip
					class="feedback-icon h-[44px] w-[44px] justify-center rounded-full bg-warm-grey-100"
					:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_SUPER_HAPPY_EMOJI_TOOLTIP'])"
					:hide-cursor="true"
					@click="onFeedbackClick(Feedback.SUPER_HAPPY)"
				>
					<div class="flex h-[44px] w-[44px]">
						<span class="m-auto text-[22px]">😍</span>
					</div>
				</SpTooltip>
			</div>
		</div>
	</div>
</template>

<style scoped>
.feedback-icon {
	filter: grayscale(100%);

	transition: ease-in-out 0.2s;
	cursor: pointer;
}

.feedback-icon:hover {
	filter: grayscale(0%);

	scale: 1.3;

	background: linear-gradient(180deg, #8a9bf0 0%, #aef0f3 100%);
	box-shadow:
		0px 4px 14px 0px rgba(174, 250, 243, 0.85),
		0px -4px 12px 0px rgba(81, 84, 219, 0.4),
		0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
}
</style>
